import React from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';

export default function GeneralSettings() {
  const [hackerLevel, setHackerLevel] = React.useState('Novice');
  const [interests, setInterests] = React.useState(['Vulnerability Disclosure Program (VDP)']);
  const [frequency, setFrequency] = React.useState('Fulltime');
  const [collaboration, setCollaboration] = React.useState('Sure');

  const handleInterestChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (interests.length < 3) {
        setInterests([...interests, value]);
      }
    } else {
      setInterests(interests.filter(interest => interest !== value));
    }
  };

  return (
    <Box className="general-settings">
      <Box className="settings-section">
        <Typography variant="h6">What best Describes your hacker Experience level</Typography>
        <Typography variant="body2" >
          Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet sagittis mi tristique facilisi...
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={hackerLevel}
            onChange={(e) => setHackerLevel(e.target.value)}
            className="radio-group"
          >
            <FormControlLabel value="Novice" control={<Radio />} label="Novice" />
            <FormControlLabel value="Starter" control={<Radio />} label="Starter" />
            <FormControlLabel value="Advanced" control={<Radio />} label="Advanced" />
            <FormControlLabel value="Expert" control={<Radio />} label="Expert" />
            <FormControlLabel value="Legend" control={<Radio />} label="Legend" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box className="settings-section">
        <Typography variant="h6">Select the top 3 opportunity & Intrests</Typography>
        <Typography variant="body2">Select top 3 opportunities you are interested in</Typography>
        <FormControl component="fieldset">
          <Box className="checkbox-group">
            <FormControlLabel
              control={<Checkbox 
                checked={interests.includes('Vulnerability Disclosure Program (VDP)')}
                onChange={handleInterestChange}
                value="Vulnerability Disclosure Program (VDP)"
              />}
              label="Vulnerability Disclosure Program (VDP)"
            />
            <FormControlLabel
              control={<Checkbox 
                checked={interests.includes('Bug Bounty Program(BBP)')}
                onChange={handleInterestChange}
                value="Bug Bounty Program(BBP)"
              />}
              label="Bug Bounty Program(BBP)"
            />
            <FormControlLabel
              control={<Checkbox 
                checked={interests.includes('Capture the Flag(CTF)')}
                onChange={handleInterestChange}
                value="Capture the Flag(CTF)"
              />}
              label="Capture the Flag(CTF)"
            />
            <FormControlLabel
              control={<Checkbox 
                checked={interests.includes('Pentest')}
                onChange={handleInterestChange}
                value="Pentest"
              />}
              label="Pentest"
            />
            <FormControlLabel
              control={<Checkbox 
                checked={interests.includes('Code Audit')}
                onChange={handleInterestChange}
                value="Code Audit"
              />}
              label="Code Audit"
            />
          </Box>
        </FormControl>
      </Box>

      <Box className="settings-section">
        <Typography variant="h6">How often do you hack?</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            className="radio-group"
          >
            <FormControlLabel value="Fulltime" control={<Radio />} label="Fulltime" />
            <FormControlLabel value="Part-time" control={<Radio />} label="Part-time" />
            <FormControlLabel value="Occasionally" control={<Radio />} label="Occasionally" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box className="settings-section">
        <Typography variant="h6">Would you like to collaborate with other hackers?</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={collaboration}
            onChange={(e) => setCollaboration(e.target.value)}
            className="radio-group"
          >
            <FormControlLabel value="Sure" control={<Radio />} label="Sure" />
            <FormControlLabel value="Not now" control={<Radio />} label="Not now" />
            <FormControlLabel value="Not sure" control={<Radio />} label="Not sure" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
