import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  Select,
  MenuItem,
  Divider,
  Collapse,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import "../../styles/components/vault/vaultkyc.css";

export default function KycModal({ open, onClose }) {
  const [documentType, setDocumentType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setFileUrl(url);
  };

  const handleDeleteFile = () => {
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl);
    }
    setSelectedFile(null);
    setFileUrl(null);
    setShowPreview(false);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const FilePreview = () => {
    const isPdf = selectedFile?.type === "application/pdf";

    return (
      <Box className="file-preview">
        {isPdf ? (
          <iframe
            src={fileUrl}
            width="100%"
            height="400px"
            style={{ border: "none" }}
            title="PDF Preview"
          />
        ) : (
          <img
            src={fileUrl}
            alt="Document Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="kyc-modal-title">
      <Box className="kyc-modal">
        {/* Header */}
        <Box className="kyc-modal-header">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AddIcon />
            <Typography variant="subtitle1">Kyc(Government ID)</Typography>
          </Box>
          <IconButton onClick={onClose} size="small" className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box className="kyc-modal-content">
          <Typography variant="subtitle1" sx={{ fontWeight: "medium", mb: 2 }}>
            Document
          </Typography>
          <Divider sx={{ my: 4, width: "100%" }} />

          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Document Type
          </Typography>

          <Select
            fullWidth
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            displayEmpty
            className="kyc-document-select"
          >
            <MenuItem value="">Government ID</MenuItem>
            <MenuItem value="passport">Passport</MenuItem>
            <MenuItem value="driving_license">Driving License</MenuItem>
            <MenuItem value="national_id">National ID</MenuItem>
          </Select>

          {documentType && (
            <>
              {/* Document Display and Upload Section */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* File Display */}
                <Grid size={{ xs: 6 }}>
                  {selectedFile && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Box className="uploaded-file">
                        <Box
                          sx={{
                            mb: 1,
                            color: "#57A885",
                            border: "1px solid #57A885",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            sx={{ margin: "auto" }}
                          >
                            PDF
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          {selectedFile.name}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <IconButton
                          size="small"
                          onClick={togglePreview}
                          color={showPreview ? "primary" : "default"}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={handleDeleteFile}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Grid>

                {/* Upload Zone */}
                <Grid size={{ xs: 6 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className="kyc-upload-button"
                    component="label"
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <CloudUploadOutlinedIcon sx={{ fontSize: 40, mb: 1 }} />
                      <Typography variant="body2">Upload Back side</Typography>
                    </Box>
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                      accept="image/*,.pdf"
                    />
                  </Button>
                </Grid>
              </Grid>

              {/* File Preview */}
              <Collapse in={showPreview} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 3 }}>
                  <FilePreview />
                </Box>
              </Collapse>

              {/* Confirmation Text */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 4, mb: 2 }}
              >
                I confirm that I uploaded a valid government ID card
              </Typography>

              {/* Upload Document Button */}
              <Button
                fullWidth
                variant="outlined"
                className="kyc-upload-button-bottom"
                endIcon={<AddIcon />}
              >
                Upload Document
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
