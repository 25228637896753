import React from "react";
import { Box, Button } from "@mui/material";

import { ReactComponent as GeneralIcon } from "../../assets/icons/general-icon.svg";
import { ReactComponent as CerificationIcon } from "../../assets/icons/certificaition-icon.svg";
import { ReactComponent as SkillIcon } from "../../assets/icons/skills-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link-icon.svg";
import GeneralSettings from "./GeneralSettings";
import SocialsComponent from "./SocialsComponent";
import ProfileView from "./ProfileView";
import CertificationsComponent from "./CertificationsComponent";

export default function SettingsComponent() {
  const [tab, setTab] = React.useState(0);
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);

  const handleProfileOpen = () => {
    setIsProfileOpen(true);
  };

  const handleProfileClose = () => {
    setIsProfileOpen(false);
  };

  return (
    <Box className="settings-container">
      <Box className="settings-tabs settings-content-tabs">
        <Button
          startIcon={<GeneralIcon alt="general-icon" />}
          className={`${tab === 0 ? "active-tab" : ""}`}
          onClick={() => setTab(0)}
        >
          <span className="tab-label">General</span>
        </Button>
        <Button
          startIcon={<LinkIcon alt="link-icon" />}
          className={`${tab === 1 ? "active-tab" : ""}`}
          onClick={() => setTab(1)}
        >
          <span className="tab-label">Socials</span>
        </Button>
        <Button
          startIcon={<SkillIcon alt="skills-icon" />}
          className={`${tab === 2 ? "active-tab" : ""}`}
          onClick={() => handleProfileOpen()}
        >
          <span className="tab-label">Experience & Skills</span>
        </Button>
        <Button
          startIcon={<CerificationIcon alt="certif-icon" />}
          className={`${tab === 3 ? "active-tab" : ""}`}
          onClick={() => setTab(3)}
        >
          <span className="tab-label">Certifications</span>
        </Button>
      </Box>
      {tab === 0 && <GeneralSettings />}
      {tab === 1 && <SocialsComponent />}
      {tab === 3 && <CertificationsComponent />}
      
      <ProfileView 
        open={isProfileOpen} 
        onClose={handleProfileClose}
      />
    </Box>
  );
}
