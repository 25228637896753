import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VisibilityOutlined } from "@mui/icons-material";

import "../../styles/settingspage.css";
import HackerLayout from "../../componants/layout/HackerLayout";

import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as SettingIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as NotifIcon } from "../../assets/icons/notification-icon.svg";
import { ReactComponent as SecurityIcon } from "../../assets/icons/security-icon.svg";
import { ReactComponent as UpdateIcon } from "../../assets/icons/interest-icon.svg";
import ProfileInfoComponant from "../../componants/settings/ProfileInfoComponant";
import SettingsComponent from "../../componants/settings/SettingsComponent";
import NotifSettings from "../../componants/settings/NotifSettings";
import SecuritySettings from "../../componants/settings/SecuritySettings";

const SettingsPage = () => {
  const [tab, setTab] = React.useState(0);

  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box className="vault-page settings-page" px={3}>
          <Box className="page-title" display="flex" mb={5}>
            <SettingIcon alt="settings-icon" />
            <Typography
              variant="h3"
              ml={1}
              sx={{ fontWeight: 600, fontSize: "16px" }}
            >
              System Config
            </Typography>
          </Box>

          <Box className="settings-tabs">
            <Button
              startIcon={<ProfileIcon alt="profile-icon" />}
              className={`${tab === 0 ? "active-tab" : ""}`}
              onClick={() => setTab(0)}
            >
              Profile
            </Button>
            <Button
              startIcon={
                <NotifIcon
                  alt="notif-icon"
                  sx={{ transform: "rotate(80deg)" }}
                />
              }
              className={`${tab === 1 ? "active-tab" : ""}`}
              onClick={() => setTab(1)}
            >
              Notifications
            </Button>
            <Button
              startIcon={<SecurityIcon alt="security-icon" />}
              className={`${tab === 2 ? "active-tab" : ""}`}
              onClick={() => setTab(2)}
            >
              Security
            </Button>
          </Box>
          
          {tab === 0 && (
            <>
              <Grid container spacing={2} mt={4}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <ProfileInfoComponant />
                </Grid>

                <Grid size={{ xs: 12, md: 8 }}>
                  <SettingsComponent />
                </Grid>
              </Grid>
              <Box className="save-changes">
                <IconButton className="preview-button">
                  <VisibilityOutlined />
                </IconButton>
                <Button
                  endIcon={<UpdateIcon alt="update-icon" />}
                  variant="contained"
                  className="save-changes-button"
                >
                  Update Profile
                </Button>
              </Box>
            </>
          )}
          {tab === 1 && <NotifSettings />}
          {tab === 2 && <SecuritySettings />}
        </Box>
      )}
    </HackerLayout>
  );
};

export default SettingsPage;
