import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/x-icon.svg";

export default function SocialsComponent() {
  const [socials, setSocials] = useState([
    { platform: "Discord", url: "", icon: <DiscordIcon /> },
    { platform: "Instagram", url: "", icon: <InstagramIcon /> },
    { platform: "Twitter", url: "", icon: <TwitterIcon /> },
  ]);

  const [selectedPlatform, setSelectedPlatform] = useState("");

  const handleUrlChange = (index, value) => {
    const newSocials = [...socials];
    newSocials[index].url = value;
    setSocials(newSocials);
  };

  const handleDelete = (index) => {
    const newSocials = socials.filter((_, i) => i !== index);
    setSocials(newSocials);
  };

  const handleAddSocial = () => {
    if (selectedPlatform) {
      const platformIcons = {
        LinkedIn: "💼",
        GitHub: "💻",
        YouTube: "🎥",
        Facebook: "👥",
      };

      setSocials([
        ...socials,
        {
          platform: selectedPlatform,
          url: "",
          icon: platformIcons[selectedPlatform],
        },
      ]);
      setSelectedPlatform("");
    }
  };

  return (
    <Box className="socials-settings">
      <Typography variant="h6">Social Links</Typography>
      <Typography variant="body2">
        Connect your profile to social channels for intel drops, networking, and
        a little bragging. Show the world where to find you—if you want them to!
      </Typography>

      <Box className="social-links-container">
        {socials.map((social, index) => (
          <Box key={index} className="social-link-item">
            <Box className="platform-info">
              <Typography>{social.platform}</Typography>
              <span className="platform-icon">{social.icon} :</span>
            </Box>
            <TextField
              fullWidth
              placeholder="https://"
              value={social.url}
              onChange={(e) => handleUrlChange(index, e.target.value)}
            />
            <IconButton
              onClick={() => handleDelete(index)}
              className="delete-button"
            >
              <DeleteIcon alt="delete-icon" />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box className="add-social-section">
        <Select
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
          displayEmpty
          className="platform-select"
        >
          <MenuItem value="" disabled>
            Select Social
          </MenuItem>
          <MenuItem value="LinkedIn">LinkedIn</MenuItem>
          <MenuItem value="GitHub">GitHub</MenuItem>
          <MenuItem value="YouTube">YouTube</MenuItem>
          <MenuItem value="Facebook">Facebook</MenuItem>
        </Select>
        <Button
          variant="text"
          onClick={handleAddSocial}
          disabled={!selectedPlatform}
          endIcon={<AddIcon />}
          className="add-social-button"
        >
          Add social
        </Button>
      </Box>
    </Box>
  );
}
