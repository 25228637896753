import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { AccountBoxOutlined, Settings } from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";

import "../../styles/components/settings/security-settings.css";

export default function TwoFAModal({ onClose, open }) {
  const [choice, setChoice] = React.useState();
  const [step, setStep] = React.useState(0);
  const [verificationCode, setVerificationCode] = React.useState(["", "", "", "", ""]);
  const [showToast, setShowToast] = React.useState(false);
  const secretKey = "kv3d-gb5n-k6hf-khfp";

  const handleAuthenticatorSetup = () => {
    setChoice(1);
    console.log("Setting up authenticator");
  };

  const handleEmailSetup = () => {
    setChoice(2);
    console.log("Setting up email verification");
  };

  const handleChangeStep = () => {
    if (choice === 1) {
      setStep(1);
    }else if (choice === 2) {
      setStep(2);
    } else {
      setStep(0);
    } 
    console.log("set step ***", step);
  };

  const handleVerificationCodeChange = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    // Move to next input if value is entered
    if (value && index < 4) {
      const nextInput = document.querySelector(`input[name=code-${index + 1}]`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === "Backspace" && !verificationCode[index] && index > 0) {
      const prevInput = document.querySelector(`input[name=code-${index - 1}]`);
      if (prevInput) prevInput.focus();
    }
  };

  const handleConfirm = () => {
    setShowToast(true);
    // Add any additional confirmation logic here
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  if (!open) return null;

  return (
    <>
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="kyc-modal-title twofa-modal"
    >
      <Box className="kyc-modal">
        {/* Header */}
        <Box className="kyc-modal-header">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Settings alt="settings-icon" />
            <Typography variant="subtitle1">2FA</Typography>
          </Box>
          <IconButton onClick={onClose} size="small" className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box className="kyc-modal-content">
          {step === 0 && (
            <Box>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                2FA (Two-factor authentication)
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Add an extra layer to your login with 2-Factor
                Authentication—because one password just isn't hardcore enough.
              </Typography>
              <Divider sx={{ mb: 3, width: "100%" }} />

              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    className="twofa-option recommended"
                    sx={{
                      borderColor: choice === 1 ? "#9c27b0" : "",
                    }}
                    onClick={handleAuthenticatorSetup}
                  >
                    <Box className="twofa-option-icon">
                      <AccountBoxOutlined />
                    </Box>
                    <Typography variant="subtitle1" component="Box">
                      Set up using Authenticator App
                      <span className="recommended-tag">Recommended</span>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Deleteing your account would remove all information
                      associated to this account
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    className="twofa-option"
                    sx={{ borderColor: choice === 2 ? "#9c27b0" : "" }}
                    onClick={handleEmailSetup}
                  >
                    <Box className="twofa-option-icon">
                      <EmailOutlinedIcon />
                    </Box>
                    <Typography variant="subtitle1" component="Box">
                      Set up with Email
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      We would send a verification code to your email
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ mb: 3, width: "100%" }} />

              <Box className="modal-actions">
                <Box
                  sx={{
                    height: "35px",
                    width: "2px",
                    background: "#E9ECEC",
                    mr: 2,
                  }}
                />
                <Button
                  variant="button"
                  onClick={onClose}
                  className="action-btn cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  variant="button"
                  className="action-btn"
                  onClick={()=> {handleChangeStep()}}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="auth-title"
                textAlign="center"
              >
                Authenticator App
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                className="auth-description"
              >
                Complete the steps to configure your Auth App
              </Typography>
              <Divider className="auth-divider" />

              <Box className="auth-setup-container">
                <Typography variant="body1" className="auth-step">
                  1. Install any authenticator of your choice i.e Google
                  Authenticator, Microsoft Authenticator
                </Typography>
                <Typography variant="body1" className="auth-step-2">
                  2. Scan the image Below
                </Typography>

                <Box className="qr-code-container">
                  <QRCodeSVG
                    value={`otpauth://totp/TekLab:user@email.com?secret=${secretKey}&issuer=TekLab`}
                    size={200}
                    level="H"
                    className="qr-code-image"
                  />
                </Box>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="backup-code-info"
                >
                  If you are unable to scan the Image Above, enter the following
                  code in your preffered downloaded app
                </Typography>

                <Box className="backup-code-box">
                  <Typography variant="body1" className="backup-code">
                    {secretKey}
                  </Typography>
                </Box>
              </Box>

              <Divider className="auth-divider" />

              <Box
                className="modal-actions"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  className="action-btn"
                  fullWidth
                  onClick={() => setStep(2)}
                >
                  Proceed - All done
                </Button>
                <Button
                  onClick={() => {
                    setStep(0);
                  }}
                  className="action-btn cancel-btn"
                  fullWidth
                >
                  go back
                </Button>
              </Box>
            </Box>
          )}
          {step === 2 && (
            <Box className="verify-container">
              <Typography variant="h6" component="h2" className="verify-title">
                {choice === 1
                  ? "Verify you are all set binding Authenticator App"
                  : " Check your Email Address"}
              </Typography>
              <Typography variant="body2" className="verify-subtitle">
                {choice === 1
                  ? "Inpute code from Authentication App"
                  : "Enter the 6-digit verification code sent to " +
                    <strong>{"ab*********gmail.com"} </strong>}
              </Typography>
              {choice === 2 && (
                <Typography variant="subtitle2">
                  Resend code in <strong>0 </strong>
                </Typography>
              )}

              <Box className="verification-code-container">
                {verificationCode.map((code, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    name={`code-${index}`}
                    className="verification-input"
                    value={code}
                    onChange={(e) =>
                      handleVerificationCodeChange(index, e.target.value)
                    }
                    onKeyDown={(e) => handleKeyDown(index, e)}
                  />
                ))}
              </Box>

              <Box
                className="modal-actions"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "400px",
                  margin: "0 auto",
                }}
              >
                <Button 
                  className="action-btn" 
                  fullWidth
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
                <Button
                  onClick={() => setStep(0)}
                  className="action-btn cancel-btn"
                  fullWidth
                >
                  go back
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
    <Snackbar 
      open={showToast} 
      autoHideDuration={2000} 
      onClose={() => setShowToast(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity="success" className="toast-alert">
      <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "16px" }}>
        Successfully Enabled 2FA
      </Typography>
      <Typography variant="body2" sx={{ color: "#428568" }}>
        You’ve successfully enabled Two-Factor Authentication (2FA) for your account
      </Typography>
      </Alert>
    </Snackbar>
    </>
  );
}
