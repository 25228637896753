import React, { useState } from "react";

import "../../styles/components/vault/vaultkyc.css";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export default function BankDetailsForm({ onClose, open }) {
  const [formData, setFormData] = useState({
    country: "",
    bankName: "",
    accountNumber: "",
    address1: "",
    address2: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="kyc-modal-title">
      <Box className="kyc-modal">
        {/* Header */}
        <Box className="kyc-modal-header">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AddIcon />
            <Typography variant="subtitle1">Bank Details</Typography>
          </Box>
          <IconButton onClick={onClose} size="small" className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box className="kyc-modal-content">
          <Typography variant="subtitle1" sx={{ fontWeight: "600", mb: 2 }}>
            Bank Details
          </Typography>
          <Divider sx={{ my: 4, width: "100%" }} />
          <form onSubmit={handleSubmit} className="bank-details-form">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }} className="form-group">
                <label>Country</label>
                <Select
                  fullWidth
                  value={formData.country}
                  onChange={handleChange}
                  displayEmpty
                  className="kyc-document-select "
                  sx={{ height: "48px", mb: 1 }}
                >
                  <MenuItem value="">France</MenuItem>
                  <MenuItem value="passport">Nigeria</MenuItem>
                  <MenuItem value="driving_license">Benin</MenuItem>
                  <MenuItem value="national_id">Togo</MenuItem>
                </Select>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Bank Name"
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Account Number"
                />
              </Grid>

              <Grid size={{ xs: 12 }} className="form-group">
                <label>Address(1)</label>
                <input
                  type="text"
                  name="address1"
                  value={formData.address1}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Address"
                />
              </Grid>

              <Grid size={{ xs: 12 }} className="form-group">
                <label>Address(2)</label>
                <input
                  type="text"
                  name="address2"
                  value={formData.address2}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Address"
                />
              </Grid>
              <Button
                fullWidth
                variant="outlined"
                className="kyc-upload-button-bottom"
                endIcon={<AddIcon />}
                type="submit"
              >
                Add Bank
              </Button>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
