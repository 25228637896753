import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Avatar,
  Chip,
  Link,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import WebIcon from "@mui/icons-material/Web";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { ReactComponent as HeartsIcon } from "../../assets/icons/heart-icons.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as StatsIcon } from "../../assets/icons/stats-icon.svg";
import { ReactComponent as SignalIcon } from "../../assets/icons/signal-icon.svg";
import { ReactComponent as BlockIcon } from "../../assets/icons/block-icon.svg";
import { ReactComponent as FingerPrintIcon } from "../../assets/icons/fingerprint-icon.svg";
import { ReactComponent as RankingIcon } from "../../assets/icons/legends-icon.svg";

export default function ProfileView({ open, onClose }) {
  const profile = {
    name: "David Adegoke",
    avatar: "/path/to/avatar.jpg",
    about:
      "Lorem ipsum dolor sit amet consectetur. Sit ultrices nulla aliquot amet sagittis mi tristique facilisi...",
    stats: {
      reputation: 100,
      globalRank: 100,
      signal: 100,
      impact: 100,
    },
    badges: ["BBP", "VDP", "CTF"],
    socialLinks: [
      {
        // url: "https://teklabspace.com/",
        url: "https://teklabspace.com/",
        platform: "Website",
        icon: <WebIcon />,
      },
      {
        url: "https://teklabspace.com/",
        platform: "Instagram",
        icon: <InstagramIcon />,
      },
      {
        url: "https://teklabspace.com/",
        platform: "Twitter",
        icon: <TwitterIcon />,
      },
      {
        url: "https://teklabspace.com/",
        platform: "LinkedIn",
        icon: <LinkedInIcon />,
      },
    ],
    certificates: [
      {
        title: "UX designs",
        organization: "Classbox",
        date: "09/2024 - 08/2025",
      },
      {
        title: "UX designs",
        organization: "Classbox",
        date: "09/2024 - 08/2025",
      },
      {
        title: "UX designs",
        organization: "Classbox",
        date: "09/2024 - 08/2025",
      },
    ],
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
          overflow: "visible",
        },
      }}
    >
      <Box
        sx={{
          bgcolor: "#0D0E12",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "12px 12px 0 0",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ color: "white", fontWeight: 500 }}
        >
          Recon Station: Hacker Profile
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        <Box className="profile-view">
          {/* Header Section */}
          <Box className="profile-header-section">
            <Box>
              <Avatar
                src={profile.avatar}
                alt={profile.name}
                sx={{
                  width: 96,
                  height: 96,
                  borderRadius: 1,
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  mt: 1,
                }}
              >
                {profile.name}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  mb: 2,
                }}
              >
                <HeartsIcon />
                Interrest
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {profile.badges.map((badge, index) => (
                  <Chip
                    key={index}
                    label={badge}
                    size="small"
                    sx={{
                      bgcolor: "#F3F5F5",
                      color: "#1A1A1A",
                      fontWeight: 500,
                      fontSize: "12px",
                      borderRadius: 1,
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }} className="about-section">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "text.secondary",
                  mb: 2,
                  fontWeight: 500,
                }}
              >
                <ProfileIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {" "}
                  About :
                </Typography>
              </Box>
              <Divider width="100%" sx={{ mb: 2 }} />
              <Typography variant="body2">{profile.about}</Typography>
            </Grid>
            {/* Social Links Section */}
            <Grid size={{ xs: 12, md: 6 }} className="social-links-section">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "text.secondary",
                  mb: 2,
                  fontWeight: 500,
                }}
              >
                <LinkIcon alt="link-icon" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {" "}
                  Website & Social Links
                </Typography>
              </Box>
              <Divider width="100%" sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {profile.socialLinks.map((link, index) => (
                  <Grid size={6} key={index}>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1.5,
                        color: "text.primary",
                        "&:hover": {
                          bgcolor: "#F5F5F5",
                        },
                      }}
                    >
                      {link.icon}
                      <Link
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                      >
                        {link.url}
                      </Link>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* Stats Section */}

          <Box className="stats-section">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "text.secondary",
                m: 1,
                mb: 0,
                p: 1,
                fontWeight: 500,
              }}
            >
              <StatsIcon alt="link-icon" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {" "}
                Stats
              </Typography>
            </Box>
            <Divider width="100%" sx={{ mb: 2 }} />
            <Grid container spacing={3} className="stats-grid">
              {Object.entries(profile.stats).map(([key, value], index) => (
                <Grid size={3} className="stat-item" key={key}>
                  <Box className="stat">
                    {key === "reputation" && (
                      <FingerPrintIcon alt="fingerprint-icon" />
                    )}
                    {key === "globalRank" && <RankingIcon alt="rank-icon" />}
                    {key === "signal" && <SignalIcon alt="signal-icon" />}
                    {key === "impact" && <BlockIcon alt="block-icon" />}

                    <Typography variant="h6" className="stat-value">
                      #{value}
                    </Typography>
                    <Typography variant="caption" className="stat-name">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </Typography>
                  </Box>
                  {index < profile.stats.length - 1 && (
                    <Box className="stat-divider" />
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Certificates Section */}
          <Box className="certificates-section">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "text.secondary",
                m: 1,
                mb: 0,
                p: 1,
                fontWeight: 500,
              }}
            >
              <StatsIcon alt="link-icon" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {" "}
                Certificates
              </Typography>
            </Box>
            <Divider width="100%" sx={{ mb: 2 }} />
            <Box className="certificates">
              {profile.certificates.map((cert, index) => (
                <Box key={index} className="certificate">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {cert.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        {cert.organization}
                      </Typography>
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                    >
                      {cert.date}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
