import React, { useState } from "react";
import { Box, Typography, Button, TextField, Divider } from "@mui/material";

import "../../styles/components/settings/settings-user.css";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";

export default function ProfileInfoComponant() {
  const [profileData, setProfileData] = useState({
    aboutMe: "",
    firstName: "",
    lastName: "",
    website: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    // Logique pour changer l'image
    console.log("Image change requested");
  };

  const handleImageDelete = () => {
    // Logique pour supprimer l'image
    console.log("Image delete requested");
  };

  return (
    <Box className="profile-info-card">
      <Typography variant="h6" className="profile-info-title">
        Profile Information
      </Typography>
      <Divider my={2} sx={{ width: "100%" }} />
      <Box className="profile-image-section">
        <img
          src="https://via.placeholder.com/150"
          alt="Profile"
          className="profile-image"
        />
        <Box className="profile-image-actions">
          <Button
            endIcon={<EditIcon alt="edit-icon" />}
            className="profile-image-button change"
            onClick={handleImageChange}
          >
            Change image
          </Button>
          <Button
            endIcon={<DeleteIcon />}
            className="profile-image-button delete"
            onClick={handleImageDelete}
          >
            Delete Image
          </Button>
        </Box>
      </Box>

      <Box component="form" noValidate width={"100%"}>
        <Box className="profile-form-group">
          <Typography variant="body2" component="label">
            About Me
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="aboutMe"
            value={profileData.aboutMe}
            onChange={handleChange}
            placeholder="About me"
            variant="outlined"
            className="profile-textarea"
          />
        </Box>

        <Box className="profile-form-group">
          <Typography variant="body2" component="label">
            First Name
          </Typography>
          <TextField
            fullWidth
            name="firstName"
            value={profileData.firstName}
            onChange={handleChange}
            placeholder="First name"
            variant="outlined"
            className="profile-input"
          />
        </Box>

        <Box className="profile-form-group">
          <Typography variant="body2" component="label">
            Last Name
          </Typography>
          <TextField
            fullWidth
            name="lastName"
            value={profileData.lastName}
            onChange={handleChange}
            placeholder="Last name"
            variant="outlined"
            className="profile-input"
          />
        </Box>

        <Box className="profile-form-group">
          <Typography variant="body2" component="label">
            Website
          </Typography>
          <TextField
            fullWidth
            name="website"
            value={profileData.website}
            onChange={handleChange}
            placeholder="https://"
            variant="outlined"
            className="profile-input"
          />
        </Box>
      </Box>
    </Box>
  );
}
