import React, { useState } from "react";
import { Box, Typography, Button, Paper, Stack, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import "../../styles/components/settings/security-settings.css";
import TwoFAModal from "./2FAModal";

const SecuritySettings = () => {
  const email = "davidboostupnow@gmail.com"; // This should come from your auth context or state management
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [is2FAModalOpen, setIs2FAModalOpen] = useState(false);

  return (
    <Stack spacing={3}>
      <Paper elevation={0} sx={{ p: 3, mt: 3 }} className="email-section">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "600", fontSize: "16px" }}
        >
          Email Address
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Your email address is{" "}
          <span style={{ fontWeight: "600" }}>{email}</span>
        </Typography>
        <Button className="change-email-btn">Change Email</Button>
        <Typography className="note">
          **Turn on 2FA to be able to change your email Address**
        </Typography>
      </Paper>

      <Paper elevation={0} sx={{ p: 3 }} className="password-section">
        {!isChangingPassword ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                Password Vault
              </Typography>
              <Typography variant="body1">
                Secure your access with a strong password. Remember: the best
                keys are hard to crack!
              </Typography>
            </Box>
            <Button
              className="change-password-btn"
              onClick={() => setIsChangingPassword(true)}
            >
              Change Password
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", fontSize: "16px", mb: 3 }}
            >
              Password Vault
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Secure your access with a strong password. Remember: the best keys
              are hard to crack!
            </Typography>

            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box>
                  <Typography sx={{ mb: 1, fontWeight: "500" }}>
                    Current Password
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                    <input
                      type="password"
                      placeholder="current password"
                      className="password-input"
                      
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
              <Box >
                <Typography sx={{ mb: 1, fontWeight: "500" }}>
                  New password
                </Typography>
                <Box sx={{ position: "relative" }}>
                  <input
                    type="password"
                    placeholder="New Password"
                   className="password-input"
                  />
                </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
              <Button
                onClick={() => setIsChangingPassword(false)}
                sx={{
                  color: "#A633C0",
                  textTransform: "none",
                  fontWeight: "600",
                  "&:hover": { background: "none" },
                }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#A633C0",
                  textTransform: "none",
                  fontWeight: "600",
                  "&:hover": { bgcolor: "#7d3c98" },
                }}
              >
                Update password
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper elevation={0} className="twofa-section">
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                2FA (Two-factor authentication)
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Add an extra layer to your login with 2-Factor
                Authentication—because one password just isn't hardcore enough.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    2FA
                  </Typography>
                  <Chip label="Disabled" className="turn-on-chip" />
                </Box>
                <Button onClick={() => setIs2FAModalOpen(true)} className="turn-on-btn">Turn on</Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Backup codes:
                  </Typography>
                  <Chip label="5 remaining" className="backup-chip" />
                </Box>
                <Button className="regenerate-btn">Regenerate</Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Paper elevation={0} className="sessions-section">
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", fontSize: "16px", mb: 1 }}
            >
              Sessions
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Add an extra layer to your login with 2-Factor
              Authentication—because one password just isn't hardcore enough.
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="sessions-info">IP address:</Typography>
                <Typography className="sessions-value">192.168.0.01</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="sessions-info">User Agent:</Typography>
                <Typography className="sessions-value">192.168.0.01</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="sessions-info">Signed In:</Typography>
                <Typography className="sessions-value">192.168.0.01</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="sessions-info">Last Used at:</Typography>
                <Typography className="sessions-value">192.168.0.01</Typography>
              </Box>
            </Box>

            <Button className="revoke-btn" fullWidth sx={{ mt: 3 }}>
              Revoke
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={0} sx={{ p: 3 }} className="password-section">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", fontSize: "16px" }}
            >
              Delete Account
            </Typography>
            <Typography variant="body1">
              Deleteing your account would remove all information associated to
              this account
            </Typography>
          </Box>
          <Button className="delete-account-btn">Delete Accont</Button>
        </Box>
      </Paper>
      <TwoFAModal
        open={is2FAModalOpen}
        onClose={() => setIs2FAModalOpen(false)}
      />
    </Stack>
  );
};

export default SecuritySettings;
