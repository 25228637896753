import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import "../../styles/vaultpage.css";

import { ReactComponent as KycIcon } from "../../assets/icons/safe-verify-icon.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-arrow-icon.svg";
import KycModal from "./KycModal";

export default function KycComponant() {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      setStep(1);
    }
  }, [open]);

  return (
    <Box className="kyc-section">
      <Box className="kyc-card" style={{ width: "100%" }}>
        <Box
          className="kyc-header"
          display="flex"
          alignItems="center"
          gap={1.5}
        >
          <Box className="kyc-icon">
            <KycIcon alt="kyc-icon" />
          </Box>
          <Typography variant="h6" color="white">
            KYC
          </Typography>
        </Box>
        <Box className="kyc-content">
          <Typography variant="body2" color="text.secondary">
            Complete your KYC verification to verify your account Information to
            unlock all features.
          </Typography>

          {step === 0 && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FileIcon alt="file-icon" />}
              sx={{ width: "fit-content" }}
              onClick={handleOpen}
            >
              Start KYC process
            </Button>
          )}
          {step === 1 && (
            <Box className="kyc-document-overview">
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Government-Issued ID
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="text.secondary">
                  Status
                </Typography>
                <Typography variant="body2" className="status">
                  Pending review
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <KycModal open={open} onClose={handleClose} />
    </Box>  
  );
}
