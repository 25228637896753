import React, { useState } from "react";
import { Box, Typography, Switch, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import "../../styles/components/settings/notif-settings.css";
import AdvancedNotifModal from "./AdvancedNotifModal";

const NotifSettings = () => {
  const [showAdvancedModal, setShowAdvancedModal] = useState(false);
  const [notifications, setNotifications] = useState({
    alertPings: true,
    bountyUpdates: true,
    protocolChanges: false,
    targetScope: true,
    rewardUpdates: true,
  });

  const handleChange = (name) => (event) => {
    setNotifications({
      ...notifications,
      [name]: event.target.checked,
    });
  };

  return (
    <>
      <Box className="notif-settings-container">
        <Box className="notif-settings-header">
          <Typography variant="h6">Intel Feed Controls</Typography>
          <Typography variant="body2" color="textSecondary">
            Customize your intel stream. Flip on or off to control the flow of
            mission-critical updates.
          </Typography>
        </Box>

        <Box className="notif-settings-table">
          <Grid
            container
            spacing={2}
            className="notification-row notif-table-header"
          >
            <Grid size={8}>
              <Box className="notification-info">
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, fontSize: "16px" }}
                >
                  Type
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                Action
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="notification-row">
            <Grid size={8}>
              <Box className="notification-info">
                <Typography variant="subtitle1">Alert Pings</Typography>
                <Typography variant="body2" color="textSecondary">
                  Real-time notifications for key actions and intel drops.
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Switch
                checked={notifications.alertPings}
                onChange={handleChange("alertPings")}
                color="secondary"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="notification-row">
            <Grid size={8}>
              <Box className="notification-info">
                <Typography variant="subtitle1">
                  Bounty table updates
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Get complete updates on trades
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Switch
                checked={notifications.bountyUpdates}
                onChange={handleChange("bountyUpdates")}
                color="secondary"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="notification-row">
            <Grid size={8}>
              <Box className="notification-info">
                <Typography variant="subtitle1">Protocol Changes</Typography>
                <Typography variant="body2" color="textSecondary">
                  Be the first to know about shifts in policy.
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Switch
                checked={notifications.protocolChanges}
                onChange={handleChange("protocolChanges")}
                color="secondary"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="notification-row">
            <Grid size={8}>
              <Box className="notification-info">
                <Typography variant="subtitle1">Target Scope</Typography>
                <Typography variant="body2" color="textSecondary">
                  Stay in the loop on updates to project targets.
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Switch
                checked={notifications.targetScope}
                onChange={handleChange("targetScope")}
                color="secondary"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="notification-row">
            <Grid size={8}>
              <Box className="notification-info">
                <Typography variant="subtitle1">Reward Updates</Typography>
                <Typography variant="body2" color="textSecondary">
                  Get notified of any bounty or reward increases.
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <Switch
                checked={notifications.rewardUpdates}
                onChange={handleChange("rewardUpdates")}
                color="secondary"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="advanced-notification" sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}
        >
          Advanced Notification
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Customize your intel stream. Flip on or off to control the flow of
          mission-critical updates.
        </Typography>

        <Box className="project-updates">
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, fontSize: "14px", mb: 1 }}
            >
              Project-Specific Updates
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Tailor your updates to stay in sync with changes, rewards, and
              alerts for each project.
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              textTransform: "none",
              padding: "12px 16px",
              borderRadius: "4px",
              color: "#9333EA",
              borderColor: "#9333EA",
              "&:hover": {
                borderColor: "#9333EA",
                backgroundColor: "rgba(147, 51, 234, 0.04)",
              },
            }}
            onClick={() => setShowAdvancedModal(true)}
          >
            Set notifications
          </Button>
        </Box>
      </Box>
      {
        <AdvancedNotifModal
          open={showAdvancedModal}
          onClose={() => setShowAdvancedModal(false)}
        />
      }
    </>
  );
};

export default NotifSettings;
