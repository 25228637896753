import React from "react";
import { Box, Typography, Button } from "@mui/material";

import "../../styles/vaultpage.css";
import { ReactComponent as FileIcon } from "../../assets/icons/file-arrow-icon.svg";
import BankDetailsForm from "./BankDetailsForm";

export default function AccountComponant() {
  const [showBankModal, setShowBankModal] = React.useState(false);
  return (
    <Box className="account-section">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        className="account-header"
      >
        <Typography variant="h6" color="text.secondary">
          Bank Account
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FileIcon alt="file-icon" />}
          onClick={() => setShowBankModal(true)}
        >
          Add bank
        </Button>
      </Box>
      <Box className="account-table">
        <Box className="table-header">
          <Typography variant="body2" color="text.secondary">
            Bank Name
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Country
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Region
          </Typography>
        </Box>
        <Box className="table-content">
          <Typography variant="body2" color="text.secondary" textAlign="center">
            No bank Added yet
          </Typography>
        </Box>
      </Box>
      <BankDetailsForm
        open={showBankModal}
        onClose={() => setShowBankModal(false)}
      />
    </Box>
  );
}
