import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  Checkbox,
  InputBase,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TuneIcon from "@mui/icons-material/Tune";

import "../../styles/components/settings/notif-settings.css";

const AdvancedNotifModal = ({ open, onClose }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [projects] = useState([
    {
      id: 1,
      name: "David' Program",
      icon: "🟢",
      allReports: true,
      tailored: true,
    },
    {
      id: 2,
      name: "Rich",
      icon: "🟢",
      allReports: true,
      tailored: true,
      projectSpecific: true,
    },
  ]);

  return (
    <Modal
      sx={{ zIndex: "99999" }}
      open={open}
      onClose={onClose}
      aria-labelledby="kyc-modal-title"
    >
      <Box className="kyc-modal">
        {/* Header */}
        <Box className="kyc-modal-header">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SettingsIcon />
            <Typography variant="subtitle1">Advanced Notifications</Typography>
          </Box>
          <IconButton onClick={onClose} size="small" className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box className="kyc-modal-content advanced-modal-content">
          <Box className="project-specific-section">
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
              <ArticleOutlinedIcon />
              <Typography variant="subtitle1">
                Project-Specific Updates
              </Typography>
            </Box>
            <Divider sx={{ mb: 2, width: "100%" }} />
            <Grid container spacing={2}>
              <Grid
                size={{ xs: 12, md: 6 }}
                pl={{ xs: 0, xl: 4 }}
                className="notification-options"
              >
                <Box className="option-group">
                  <Box className="option">
                    <EmailOutlinedIcon />
                    <Typography>Tailored</Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Checkbox
                      size="small"
                      sx={{
                        color: "#A633C0",
                        "&.Mui-checked": {
                          color: "#A633C0",
                        },
                      }}
                    />
                    Enable all
                  </Typography>
                </Box>

                <Box className="option-group">
                  <Box className="option">
                    <ArticleOutlinedIcon />
                    <Typography>All reports</Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Checkbox
                      size="small"
                      sx={{
                        color: "#A633C0",
                        "&.Mui-checked": {
                          color: "#A633C0",
                        },
                      }}
                    />
                    Enable all
                  </Typography>
                </Box>
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
                className="notifs-search-container"
              >
                <IconButton>
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className="notifs-search-input"
                  placeholder="Search by Project or Username"
                  inputProps={{ "aria-label": "search" }}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton
                  onClick={() => {
                    setFilterOpen(!filterOpen);
                  }}
                >
                  <TuneIcon alt="filter-icon" />
                </IconButton>
              </Grid>
            </Grid>

            <Box className="projects-table">
              <Grid container spacing={2}>
                <Grid
                  size={{ xs: 6, md: 12 }}
                  container
                  spacing={2}
                  className="table-header"
                >
                  <Grid size={{ xs: 12, md: 3 }}>
                    <Typography>Program</Typography>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography>All reports</Typography>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography>Tailored</Typography>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 3 }}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography>No reports</Typography>
                  </Grid>
                </Grid>

                {projects.map((project) => (
                    
                  <Grid
                    size={{ xs: 6, md: 12 }}
                    container
                    key={project.id}
                    className="project-row"
                  >
                    <Grid
                      size={{ xs: 12, md: 3 }}
                      display="flex"
                      alignItems="center"
                      gap={1}
                    >
                      <span>{project.icon}</span>
                      <Typography>{project.name}</Typography>
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 3 }}
                      display="flex"
                      justifyContent="center"
                    >
                      <Checkbox
                        checked={project.allReports}
                        size="small"
                        sx={{
                          color: "#A633C0",
                          "&.Mui-checked": {
                            color: "#A633C0",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 3 }}
                      display="flex"
                      justifyContent="center"
                    >
                      <Checkbox
                        checked={project.tailored}
                        size="small"
                        sx={{
                          color: "#A633C0",
                          "&.Mui-checked": {
                            color: "#A633C0",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 3 }}
                      display="flex"
                      justifyContent="center"
                    >
                      <Checkbox
                        checked={project.tailored}
                        size="small"
                        sx={{
                          color: "#A633C0",
                          "&.Mui-checked": {
                            color: "#A633C0",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AdvancedNotifModal;
