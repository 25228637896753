import React from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import AddCertificationModal from "./AddCertificationModal";

export default function CertificationsComponent() {
  const [showCertificateModal, setShowCertificateModal] = React.useState(false);
  const certificates = [
    {
      id: 1,
      name: "UX designs",
      institution: "Classbox",
      date: "09/2024 - 08/2025",
    },
    {
      id: 2,
      name: "UX designs",
      institution: "Classbox",
      date: "09/2024 - 08/2025",
    },
  ];

  const handleEdit = (id) => {
    // Implement edit functionality
    console.log("Edit certificate:", id);
  };

  const handleDelete = (id) => {
    // Implement delete functionality
    console.log("Delete certificate:", id);
  };

  const handleAddCertificate = () => {
    setShowCertificateModal(true);
    console.log("Add new certificate");
  };

  return (
    <>
    <Box className="certifications-card">
      <Box className="certifications-header">
        <Typography variant="h6" className="certifications-title">
          Certificates
        </Typography>
        <Typography variant="body2" className="certifications-description">
          Show off your creds! Upload your certificates to prove your skills and
          back up your reputation.
        </Typography>
      </Box>

      <div style={{ width: "100%" }}>
        {certificates.map((cert) => (
          <Box key={cert.id} className="certification-item">
            <Box className="certification-info">
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography className="certification-name">
                  {cert.name}
                </Typography>
                <Typography className="certification-institution">
                  {cert.date}
                </Typography>
              </Box>
              <span className="certification-date">{cert.institution}</span>
            </Box>
            <Divider width="100%" />
            <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box className="certification-actions">
                <IconButton onClick={() => handleEdit(cert.id)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handleDelete(cert.id)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}
      </div>

      <Button className="add-certificate-button" onClick={handleAddCertificate}>
        <AddIcon /> Add Certificate
      </Button>
    </Box>
    <AddCertificationModal
        open={showCertificateModal}
        onClose={() => setShowCertificateModal(false)}
      />
    </>
  );
}
