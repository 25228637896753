import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import "../../styles/components/vault/vaultkyc.css";

export default function AddCertificationModal({ onClose, open }) {
  const [formData, setFormData] = useState({
    certificateName: "",
    organization: "",
    certificateId: "",
    certificateUrl: "",
    issueMonth: "",
    issueYear: "",
    expirationMonth: "",
    expirationYear: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="kyc-modal-title">
      <Box className="kyc-modal">
        {/* Header */}
        <Box className="kyc-modal-header">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AddIcon />
            <Typography variant="subtitle1">Add Certificate</Typography>
          </Box>
          <IconButton onClick={onClose} size="small" className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box className="kyc-modal-content">
          <Typography variant="subtitle1" sx={{ fontWeight: "600", mb: 2 }}>
            Certificate
          </Typography>
          <Divider sx={{ my: 4, width: "100%" }} />
          <form onSubmit={handleSubmit} className="bank-details-form">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }} className="form-group">
                <label>Certificate name</label>
                <input
                  type="text"
                  name="certificateName"
                  value={formData.certificateName}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Certificate name"
                />
              </Grid>

              <Grid size={{ xs: 12 }} className="form-group">
                <label>Organization</label>
                <input
                  type="text"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Organization"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Certificate ID</label>
                <input
                  type="text"
                  name="certificateId"
                  value={formData.certificateId}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Certificate ID"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Certificate URL</label>
                <input
                  type="text"
                  name="certificateUrl"
                  value={formData.certificateUrl}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="https://"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Issue Month</label>
                <input
                  type="text"
                  name="issueMonth"
                  value={formData.issueMonth}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Month"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Issue Year</label>
                <input
                  type="text"
                  name="issueYear"
                  value={formData.issueYear}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Year"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Expiration Month</label>
                <input
                  type="text"
                  name="expirationMonth"
                  value={formData.expirationMonth}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Month"
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className="form-group">
                <label>Expiration Year</label>
                <input
                  type="text"
                  name="expirationYear"
                  value={formData.expirationYear}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Year"
                />
              </Grid>
            </Grid>

            <Button
              fullWidth
              variant="outlined"
              className="kyc-upload-button-bottom"
              endIcon={<AddIcon />}
              type="submit"
            >
              Add Certificate
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
