import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SignUp from "./pages/auth/Signup";
import SignupCompany from "./pages/auth/Signup_company";
import Login from "./pages/auth/Login";
import DiscoveryPage from "./pages/hackers/DiscoveryPage";

import "./App.css";
import DashboardPage from "./pages/hackers/Dashaboard";
import Leaderboard from "./pages/hackers/Leaderboard";
import BountiesPage from "./pages/hackers/BountiesPage";
import BountyDetailPage from "./pages/hackers/BountyDetailsPage";
import VaultPage from "./pages/hackers/VaultPage";
import SettingsPage from "./pages/settings/SettingsPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup-company" element={<SignupCompany />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DiscoveryPage />} />
        <Route path="/discovery" element={<DiscoveryPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/vault" element={<VaultPage />} />
        <Route path="/bounties" element={<BountiesPage />} />
        <Route path="/bounties/:title" element={<BountyDetailPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
